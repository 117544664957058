import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myPic from "../../Assets/mypic.jpg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I’m a passionate MERN and Android developer.
              <br />
              <br />
              with strong proficiency in languages like
              <i>
                <b className="purple"> C, Java, JavaScript &amp; Python. </b>
              </i>
              <br />
              Additionally, I have experience with
              <i>
                <b className="purple"> Kotlin &amp; Dart. </b>
              </i>
              <br />
              <br />
              My interests lie in building new&nbsp;
              <i>
                <b className="purple">Android Apps &amp; Web Apps. </b> <br />
                Have Curiosity to exploring areas related to&nbsp;
                <b className="purple">
                  Artificial Intelligence &amp; Kali Linux .
                </b>
              </i>
              <br />
              <br />I also apply my passion for developing products with
              <i>
                <b className="purple"> Node.js</b> <br /> and&nbsp;
              </i>
              Modern Javascript Library and Frameworks like&nbsp;
              <i>
                <b className="purple"> React.js &amp; Next.js.</b>
              </i>
              <br />
              <br />
              And other cross Platform SDK like
              <b className="purple"> Flutter.</b>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myPic} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/ratulverse"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://x.com/RatulDu33879603"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaSquareXTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/pegasus1501"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/jyotirmoy-dutta-a77b43231/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/ratulverse/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
